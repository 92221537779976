@import '../App.scss';
// Your additional custom styles
.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.nav-bar ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-bar li {
    margin-right: 20px;
}

.navbar a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.bot-navbar {
    background-color: $custom-primary-color;
    height: 72px;
    color: white;
}

// .bot-navbar>.container>span {
//     color: white;
// }
.nav-link-custom {
    color: #ffffff; // Set text color for nav links
}

.bot-navbar-brand {
    font-size: large;
    font-weight: bold;
    font-weight: 800;
    margin-left: 15px;
}

.close-button {
    margin-right: 15px;
}

// Add more custom styles as needed
