.message-input {
    bottom: 10px;
    height: 64px;
    button {
        border: none;
        background-color: white;
    }
    width: inherit;
    input:focus {
        border: none !important;
        outline: none !important;
    }
}

.input-field {
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;
    position: relative;
    display: flex;
    height: 100%;
    outline: none;
}

.input-field input {
    flex-grow: 1;
    border: none;
    outline: none !important;
}

.input-field input:focus {
    box-shadow: none;
}

.submit-button:hover {
    background-color: transparent !important;
}