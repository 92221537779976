@import '../App.scss';
.minimized-chat {
    /* Remove the button from normal document flow */
    position: absolute;
    /* Position at the bottom edge */
    bottom: 10px;
    /* Position at the right edge */
    right: 10px;
    /* Set width and height for the button */
    width: 100px;
    height: 100px;
    /* Set border radius to 50% for a circle */
    border-radius: 50%;
    /* Optional styles for the button */
    background-color: $custom-primary-color;
    /* Green color */
    color: white;
    padding: 0;
    /* Remove default padding */
    text-align: center;
    /* Center text horizontally */
    line-height: 15px;
    /* Center text vertically (adjust based on font size) */
    border: none;
    /* Remove default border */
    cursor: pointer;
    /* Indicate clickable behavior */
    white-space: wrap;
}
