@import '../App.scss';
.message-list {
    flex-grow: 1;
    /* Expand to fill available space */
    margin-top: 10px;
    overflow-y: auto;
    // padding-inline-start: 10px;
    // padding-inline-end: 10px;
    border-bottom: 1px solid #ccc;
}

.message {
    padding: 8px;
    border-radius: 5px;
    // max-width: 90%;
    white-space: pre-line;
    word-break: break-word;
    width: fit-content;
    display: flex;
    align-items: baseline;
}

.user {
    text-align: right;
    flex-direction: row-reverse;
    /* Green for user messages */
    margin-left: auto;
    .message-content {
        color: white;
        background-color: $custom-primary-color;
        margin-right: 6px;
    }
}

.bot {
    text-align: left;
    flex-direction: row;
    /* Blue for bot messages */
    .message-content {
        margin-left: 6px;
        color: #18212C;
        background-color: #DDE2EB;
    }
}
