@import '../App.scss';
.chatbot-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* Enable vertical scrolling if messages exceed container height */
    height: 60vh;
    /* Set a specific height for the messages container */
    margin-top: 10px;
    width: 375px;
    height: 694px;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 10px;
    right: 10px;
    margin-right: 7%;
    /* Ensure the app takes up the full height of the viewport */
    background-color: white;
}
